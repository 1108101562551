$primary: #00AAF2;
$secondary: #01D188;
$dark: #071D2D;
$light: #FFFFFF;
$background-gray: #EFEFEF;

$border-radius: 0;

$font-family-sans-serif:
  // Cross-platform generic font family (default user interface font)
  system-ui,
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Windows
  "Segoe UI",
  // Android
  Roboto,
  // Basic web fallback
  "Helvetica Neue", Arial,
  // Linux
  "Noto Sans",
  "Liberation Sans",
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$display-font-sizes: (
        1: 4rem,
        2: 3.5rem,
        3: 3rem,
        4: 2.5rem,
        5: 2rem,
        6: 1.5rem
);