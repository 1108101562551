@import "./abstracts/variables";
@import "~bootstrap/scss/bootstrap";

.corporate-design-underline {
  border-width: 0;
  border-bottom-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to right, $primary, $secondary) 1;
}

.corporate-design-overline {
  background-color: $background-gray;
  border-width: 0;
  border-top-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to right, $primary, $secondary) 1;
}

.borderless {
  border: 0;
}

.nav-link:hover {
    text-shadow: 0 0 5px gray;
}

.typewriting {
  font-family: monospace;
  color:#0000;
  background:
    linear-gradient(-90deg,black 5px,#0000 0) 10px 0,
    linear-gradient(black 0 0) 0 0;
  background-size:calc(var(--n)*1ch) 200%;
  -webkit-background-clip:padding-box,text;
  background-clip:padding-box,text;
  background-repeat:no-repeat;
  animation:
    b .7s infinite steps(1),
    t calc(var(--n)*.1s) steps(var(--n)) forwards;
}
@keyframes t{
  from {background-size:0 200%}
}
@keyframes b{
  50% {background-position:0 -100%, 0 0}
}
